<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>disability-walking-help</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15.75,11.25a1.5,1.5,0,0,0-1.5-1.5c-3.448,0-4.62-2.1-4.658-2.171A1.571,1.571,0,0,0,8.216,6.75a1.5,1.5,0,0,0-1.337.891l-6,13.5A1.5,1.5,0,0,0,3.621,22.36L6.1,16.777a2.964,2.964,0,0,1,1.4,2.5V21.75a1.5,1.5,0,0,0,3,0V19.282A5.959,5.959,0,0,0,7.334,14l1.351-3.039A8.751,8.751,0,0,0,14.25,12.75,1.5,1.5,0,0,0,15.75,11.25Z"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="10.5" cy="3.375" r="2.625" />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="21.75" cy="21.75" r="1.5" />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="14.25" cy="21.75" r="1.5" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15.75,11.25h1.408a3,3,0,0,1,2.91,2.273L21.75,20.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.25,20.25,15,18.386A3,3,0,0,1,17.781,16.5h3.032"
    />
  </svg>
</template>
